var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit_form)}}},[_c('input',{ref:"form_template_submit_button",attrs:{"type":"submit","hidden":""}}),_vm._l((_vm.form_template_obj),function(template_item){return _c('b-row',{key:template_item.id},[_c('b-col',[(template_item.input_type == 'input')?_c('base-input',{staticClass:"mb-3",attrs:{"pill":true,"label":template_item.display_name,"name":"above","rules":template_item.validation_rules,"placeholder":template_item.placeholder},model:{value:(template_item.value),callback:function ($$v) {_vm.$set(template_item, "value", $$v)},expression:"template_item.value"}}):_vm._e(),(
                                    template_item.input_type == 'textarea'
                                )?_c('base-input',{staticClass:"mb-3",attrs:{"pill":true,"type":"textarea","rows":'rows' in template_item.settings
                                        ? String(
                                              template_item.settings.rows
                                          )
                                        : String(3),"label":template_item.display_name,"name":"above","rules":template_item.validation_rules,"placeholder":template_item.placeholder},model:{value:(template_item.value),callback:function ($$v) {_vm.$set(template_item, "value", $$v)},expression:"template_item.value"}}):_vm._e(),(
                                    template_item.input_type == 'checkbox'
                                )?_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"auto"}},[_c('b-form-checkbox',{attrs:{"name":template_item.name},model:{value:(template_item.value),callback:function ($$v) {_vm.$set(template_item, "value", $$v)},expression:"template_item.value"}})],1),_c('b-col',{staticClass:"pl-0",attrs:{"sm":"auto"}},[_c('label',{staticClass:"form-control-label",class:{
                                            strike:
                                                !template_item.value &&
                                                'enable_strike' in
                                                    template_item.settings &&
                                                template_item.settings
                                                    .enable_strike,
                                        }},[_vm._v(" "+_vm._s(template_item.display_name)+" label ")])])],1):_vm._e(),(template_item.input_type == 'quill')?_c('html-editor',{staticClass:"mb-3",attrs:{"pill":true,"label":template_item.display_name,"name":template_item.name},model:{value:(template_item.value),callback:function ($$v) {_vm.$set(template_item, "value", $$v)},expression:"template_item.value"}}):_vm._e(),(template_item.input_type == 'select')?_c('base-input',{staticClass:"mb-3",attrs:{"rules":template_item.validation_rules,"label":template_item.display_name,"name":"above"},model:{value:(template_item.value),callback:function ($$v) {_vm.$set(template_item, "value", $$v)},expression:"template_item.value"}},[(
                                        template_item.input_type == 'select'
                                    )?_c('el-select',{staticClass:"form",attrs:{"filterable":true,"name":"above"},model:{value:(template_item.value),callback:function ($$v) {_vm.$set(template_item, "value", $$v)},expression:"template_item.value"}},_vm._l((template_item
                                            .settings.options),function(option){return _c('el-option',{key:option,attrs:{"label":option,"value":option}})}),1):_vm._e()],1):_vm._e()],1)],1)})],2)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }