<template>
  <b-row>
    <b-col>
      <div>
        <!-- Decline Modal -->
        <b-modal
          id="confirmation_modal"
          title="Confirm"
          size="sm"
          no-close-on-backdrop
          no-close-on-esc
          hide-footer
        >
          <div class="text-center">
            <h1>Are you sure?</h1>
            <p v-if="confirmation_modal.mode == 'decline'">
              You are about to decline this team join request from:<br />
              <strong>"{{ confirmation_modal.request.name }}"</strong>
            </p>
            <p v-if="confirmation_modal.mode == 'approve'">
              You are about to approve this team join request from:<br />
              <strong>"{{ confirmation_modal.request.name }}"</strong>
            </p>
            <base-button
              type="danger"
              :pill="true"
              class="my-4 btn-success"
              @click="
                handle_confirmation_modal_confirm(
                  confirmation_modal.request,
                  confirmation_modal.mode
                )
              "
              >{{
                confirmation_modal.mode == "approve" ? "Approve" : "Decline"
              }}
            </base-button>

            <base-button
              type="primary"
              :pill="true"
              class="my-4 btn-success"
              @click="$bvModal.hide('confirmation_modal')"
              >Cancel
            </base-button>
          </div>
        </b-modal>
        <!-- Body -->
        <!-- <b-card no-body v-if="join_requests.length > 0"> -->
        <b-card no-body>
          <b-card-body>
            <b-row>
              <b-col sm="auto">
                <h1 class="mb-0 text-nowrap">Join Requests</h1>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <el-table
                  class="table-responsive align-items-center table-flush no-lines"
                  header-row-class-name="thead-light"
                  row-class-name="table-rows"
                  :show-header="false"
                  :data="join_requests"
                  empty-text="No New Team Join Requests"
                >
                  <el-table-column
                    label="Name"
                    min-width="100px"
                    prop="name"
                    sortable
                  >
                    <template v-slot="{ row }">
                      {{ row.name }}
                    </template>
                  </el-table-column>

                  <el-table-column align="right">
                    <div slot-scope="{ row }" class="table-actions">
                      <base-button
                        type="danger"
                        size="sm"
                        :pill="true"
                        class="my-4 btn-success"
                        @click.prevent="show_modal(row, 'decline')"
                      >
                        Decline
                      </base-button>

                      <base-button
                        type="primary"
                        size="sm"
                        :pill="true"
                        class="my-4 btn-success"
                        @click.prevent="show_modal(row, 'approve')"
                      >
                        Approve
                      </base-button>
                    </div>
                  </el-table-column>
                </el-table>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>

<script>
// Components
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
} from "element-ui";

// Queries
import { GET_HACKATHON_TEAM_JOIN_REQUESTS } from "@/graphql/queries";

// Mutations
import { UPDATE_HACKATHON_TEAM_JOIN_REQUEST } from "@/graphql/mutations";

export default {
  name: "HackathonTeamJoinRequests",
  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  emits: [],
  props: {
    team_id: {
      type: String,
      description: "The id of the team.",
      default: null,
    },
  },
  apollo: {
    get_hackathon_team_join_requests: {
      query: GET_HACKATHON_TEAM_JOIN_REQUESTS,
      result(res) {
        this.join_requests = [];
        let f_data = graph_utils.flatten_objects_recursive(
          graph_utils.apollo_to_obj_recursive(
            res.data.allHackathonTeamTeamJoinRequest
          )
        );

        f_data.forEach((el) => {
          this.join_requests.push({
            id: el.id,
            name: `${el.profile__user__firstName} ${el.profile__user__lastName}`,
          });
        });
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      variables() {
        return {
          team_id: this.team_id,
        };
      },
      update(data) {
        this.apollo_data.get_hackathon_team_join_requests = data;
      },
      skip: true,
    },
  },
  data() {
    return {
      apollo_data: {
        get_hackathon_team_join_requests: {},
      },
      confirmation_modal: {
        request: {
          id: null,
          name: null,
        },
        mode: "approve",
      },

      join_requests: [
        // {
        //     name: "Joe",
        //     id: "ID",
        // },
      ],
    };
  },
  methods: {
    // Apollo Methods
    manage_get_hackathon_team_join_requests() {
      if (this.team_id != null) {
        graph_utils.enable_query(
          this.$apollo.queries.get_hackathon_team_join_requests
        );
      } else {
        graph_utils.disable_query(
          this.$apollo.queries.get_hackathon_team_join_requests
        );
      }
    },

    // Modal methods

    show_modal(request, mode) {
      this.confirmation_modal.request = request;
      this.confirmation_modal.mode = mode; // approve or decline
      this.$bvModal.show("confirmation_modal");
    },

    handle_confirmation_modal_confirm(request, mode) {
      let status = null;
      if (mode == "approve") {
        status = "APPROVED";
      } else if (mode == "decline") {
        status = "REJECTED";
      }

      this.$apollo
        .mutate({
          mutation: UPDATE_HACKATHON_TEAM_JOIN_REQUEST,
          variables: {
            join_request_id: request.id,
            status: status,
          },
        })
        .then((res) => {
          this.$bvModal.hide("confirmation_modal");
          // Refetching the program data
          this.$apollo.queries.get_hackathon_team_join_requests.refetch();
          global_event_emitter.$emit("hackathon_team_updated");
        })
        .catch((res) => {
          // TODO: Create notification that something went wrong.
        });
    },
  },
  mounted() {
    this.manage_get_hackathon_team_join_requests();
  },
  watch: {
    team_id(new_id) {
      this.manage_get_hackathon_team_join_requests();
    },
  },
};
</script>

<style></style>