<template>
    <div>
        <div class="header pb-6 d-flex align-items-center">
            <!-- Mask -->
            <span class="mask bg-white opacity-8"></span>
            <!-- Header container -->
            <b-container fluid class="d-flex align-items-center">
                <b-row>
                    <b-col lg="12"> </b-col>
                </b-row>
            </b-container>
        </div>

        <b-container fluid class="mt--6">
            <!-- <b-row>
                <b-col class="mt-4 mb-2">
                    <div
                        @click="$router.back()"
                        class="icon icon-shape rounded-circle shadow bg-primary text-white"
                    >
                        <i class="fa fa-arrow-left"></i>
                    </div>
                </b-col>
            </b-row> -->
            <b-row>
                <b-col sm="12" lg="12">
                    <div>
                        <b-row>
                            <b-col sm="12" md="12" xl="8">
                                <HackathonTeamName
                                    :team_id="team.id"
                                    :team_name="team.name"
                                    :is_admin="team.is_admin"
                                    @hackathon_team_name_update="
                                        handle_hackathon_team_name_update()
                                    "
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="12" xl="8">
                                <HackathonTeamResources :team_id="team.id" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="12" xl="8">
                                <HackathonTeamMembers :team_id="team.id" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="12" xl="8">
                                <HackathonTeamMemberJoinRequests
                                    :team_id="team.id"
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="12" xl="8">
                                <HackathonTeamSubmission
                                    :team_id="team.id"
                                    :hackathon_id="hackathon_id"
                                />
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
// Components
import HackathonTeamName from "@/views/Components/Hackathon/Team/TeamName.vue";
import HackathonTeamResources from "@/views/Components/Hackathon/Team/TeamResources.vue";
import HackathonTeamMembers from "@/views/Components/Hackathon/Team/TeamMembers.vue";
import HackathonTeamMemberJoinRequests from "@/views/Components/Hackathon/Team/TeamMemberJoinRequests.vue";
import HackathonTeamSubmission from "@/views/Components/Hackathon/Team/Submission.vue";

import { GET_HACKATHON_TEAM } from "@/graphql/queries";

export default {
    name: "HackathonMyTeam",
    components: {
        HackathonTeamName,
        HackathonTeamResources,
        HackathonTeamMembers,
        HackathonTeamMemberJoinRequests,
        HackathonTeamSubmission,
    },
    apollo: {
        get_hackathon_team: {
            query: GET_HACKATHON_TEAM,
            result(res) {
                this.team.id = res.data.hackathonTeam.id;
                this.team.name = res.data.hackathonTeam.name;
                this.team.is_admin = res.data.hackathonTeam.isAdmin;
                this.team.is_member = res.data.hackathonTeam.isMember;
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    team_id: this.team_id,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_team = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_team: {},
            },
            hackathon_id: null,
            team: {
                id: null,
                name: null,
                is_admin: false,
                is_member: false,
            },
            get_team_enable_retries: 0,
   
        };
    },
    methods: {
        get_hackathon_id() {
            if (this.$route.params.hackathon_id) {
                this.hackathon_id = this.$route.params.hackathon_id;
            } else {
                this.hackathon_id = null;
                // TODO: We need to reroute this if there is no hackathon. Possibly to the discover hackathons page.
            }
        },

        get_team_id() {
            this.get_team_enable_retries++;
            if (this.get_team_enable_retries > 3) {
                return;
            }
            if (!("get_hackathon_team" in this.$apollo.queries)) {
                setTimeout(() => {
                    this.get_team_id();
                }, 500);
                return;
            }
            if (this.$route.params.team_id) {
                this.team_id = this.$route.params.team_id;
                graph_utils.enable_query(
                    this.$apollo.queries.get_hackathon_team
                );
                this.get_team_enable_retries = 0;
            } else {
                this.team_id = null;
                graph_utils.disable_query(
                    this.$apollo.queries.get_hackathon_team
                );
                this.get_team_enable_retries = 0;
                this.$router.push({
                    path: `/hackathon/${encodeURIComponent(this.hackathon_id)}`,
                });
            }
        },
        handle_hackathon_team_name_update() {
            this.$apollo.queries.get_hackathon_team.refetch();
        },
    },
    mounted() {
        this.get_team_id();
        this.get_hackathon_id();
    },
    watch: {
        "$route.params.team_id": {
            handler() {
                this.get_team_id();
            },
            immediate: true,
        },
        "$route.params.hackathon_id": {
            handler() {
                this.get_hackathon_id();
            },
            immediate: true,
        },
    },
};
</script>

<style></style>
