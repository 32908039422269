var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',[_c('div',[_c('b-modal',{attrs:{"id":"confirmation_modal","title":"Confirm","size":"sm","no-close-on-backdrop":"","no-close-on-esc":"","hide-footer":""}},[_c('div',{staticClass:"text-center"},[_c('h1',[_vm._v("Are you sure?")]),(_vm.confirmation_modal.mode == 'decline')?_c('p',[_vm._v(" You are about to decline this team join request from:"),_c('br'),_c('strong',[_vm._v("\""+_vm._s(_vm.confirmation_modal.request.name)+"\"")])]):_vm._e(),(_vm.confirmation_modal.mode == 'approve')?_c('p',[_vm._v(" You are about to approve this team join request from:"),_c('br'),_c('strong',[_vm._v("\""+_vm._s(_vm.confirmation_modal.request.name)+"\"")])]):_vm._e(),_c('base-button',{staticClass:"my-4 btn-success",attrs:{"type":"danger","pill":true},on:{"click":function($event){return _vm.handle_confirmation_modal_confirm(
                _vm.confirmation_modal.request,
                _vm.confirmation_modal.mode
              )}}},[_vm._v(_vm._s(_vm.confirmation_modal.mode == "approve" ? "Approve" : "Decline")+" ")]),_c('base-button',{staticClass:"my-4 btn-success",attrs:{"type":"primary","pill":true},on:{"click":function($event){return _vm.$bvModal.hide('confirmation_modal')}}},[_vm._v("Cancel ")])],1)]),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"sm":"auto"}},[_c('h1',{staticClass:"mb-0 text-nowrap"},[_vm._v("Join Requests")])])],1),_c('b-row',[_c('b-col',[_c('el-table',{staticClass:"table-responsive align-items-center table-flush no-lines",attrs:{"header-row-class-name":"thead-light","row-class-name":"table-rows","show-header":false,"data":_vm.join_requests,"empty-text":"No New Team Join Requests"}},[_c('el-table-column',{attrs:{"label":"Name","min-width":"100px","prop":"name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}}])}),_c('el-table-column',{attrs:{"align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return _c('div',{staticClass:"table-actions"},[_c('base-button',{staticClass:"my-4 btn-success",attrs:{"type":"danger","size":"sm","pill":true},on:{"click":function($event){$event.preventDefault();return _vm.show_modal(row, 'decline')}}},[_vm._v(" Decline ")]),_c('base-button',{staticClass:"my-4 btn-success",attrs:{"type":"primary","size":"sm","pill":true},on:{"click":function($event){$event.preventDefault();return _vm.show_modal(row, 'approve')}}},[_vm._v(" Approve ")])],1)}}])})],1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }